<template>
	
		
		<div class="container">
			<div >
			            <iframe id="iframe" name="iframe" src="https://h55.itouchvn8.com/choose.html" frameborder="0" width="100%" scrolling="auto”）"></iframe>

			    </div>
				
		<div class="call-mobile">
				<a class="" href="tel:0587028888"> <img src="/img/gw/phone_mobile.png" alt="phone" title="phone">0587028888<strong></strong></a>
		</div>		
				
		
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [
				{
					0: '北京',
					1: '上海',
					2: '广州',
					3: '深圳'
				},
				{
					0: '南京',
					1: '长沙',
					2: '重庆',
					3: '西安'
				}
			]
		};
	},
	mounted() {
	    this.iframeLoad()
	  },
	methods: {
		iframeLoad() {
		      document.querySelector('#iframe').style.height = 0
		      const iframe = document.querySelector('#iframe')
		      iframe.onload = function () {
		        setTimeout(() => {
		          iframe.style.height = '800px'
		        }, 500)
		      }
		    },
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			}
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
			});
		}
	},
	created() {
		this.getAddress();
	}
};
</script>

<style src='../../assets/css/ttc.css' scoped></style>
<!-- <style src='../../assets/css/responsive.css' scoped></style> -->
<!-- <style src='../../assets/css/bootstrap.min.css' scoped></style> -->

<style lang="less" scoped>
	// @import "../../assets/css/ttc.css";
	// @import "../../assets/css/responsive.css";
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}
.nav-bar {
	background: linear-gradient(90deg,#500cfd,#9e9ce7);
	height: 100px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #500cfd;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background-color: #8a637d;
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 20px;
	// margin-top: 10px;
}
.address {
	width: 94%;
	margin: 0 auto;
}
.van-cell {
    padding: 4vw 2.933vw;
    font-size: 4vw;
    line-height: 4vw;
}
.pageone-title h2 {
margin: 0px;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: bold;
    background: #fff;
    display: inline-block;
    padding-right: 10px;
	}
.pageone-title h2 {
    font-size: 15PX;
    display: block;
	text-transform: uppercase;
	    font-weight: bold;
}
// .container {
//     font-family: Inter,sans-serif;
//     font-weight: 400;
//     color: #fff!important;
// }
.container {
    // font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    // font-size: 14PX;
    // line-height: 1.42857143;
    // color: #333;
    background-color: #fff;
	z-index: 1!important;
}
.pageone-tab .nav-tabs>li {
    width: 50%!important;
}
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    float: left;
}
.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.nav-tabs {
    border-bottom: 1px solid #ddd;
}
table {
    border-spacing: 0;
    border-collapse: collapse;
}
.tab-content {
    display: block;
    // overflow-x: scroll;
}
</style>
